export const availableGames = {
    english: 'Available Games',
    polish: 'Dostępne Gry',
    russian: 'Доступные Игры',
    french: 'Jeux Disponibles',
    german: 'Verfügbare Spiele',
    spanish: 'Juegos Disponibles',
    belarusian: 'Даступныя Гульні',
};

export const startedGames = {
    english: 'Started Games',
    polish: 'Rozpoczęte Gry',
    russian: 'Начатые Игры',
    french: 'Jeux Commencés',
    german: 'Begonnene Spiele',
    spanish: 'Juegos Iniciados',
    belarusian: 'Пачатыя Гульні',
};

export const startedAt = {
    english: 'Started at',
    polish: 'Rozpoczęto o',
    russian: 'Начато в',
    french: 'Commencé à',
    german: 'Begonnen um',
    spanish: 'Comenzó a las',
    belarusian: 'Пачалося ў',
};

export const copyMySession = {
    english: 'Copy my session',
    polish: 'Skopiuj moją sesję',
    russian: 'Скопировать мою сессию',
    french: 'Copier ma session',
    german: 'Meine Sitzung kopieren',
    spanish: 'Copiar mi sesión',
    belarusian: 'Скапіяваць маю сесію',
};

export const sessionCopied = {
    english: 'Session copied into clipboard',
    polish: 'Sesja skopiowana do schowka',
    russian: 'Сессия скопирована в буфер обмена',
    french: 'Session copiée dans le presse-papiers',
    german: 'Sitzung in die Zwischenablage kopiert',
    spanish: 'Sesión copiada al portapapeles',
    belarusian: 'Сесія скапіраваная ў буфер абмену',
};

export const deleteAllGames = {
    english: 'Delete all started games',
    polish: 'Usuń wszystkie rozpoczęte gry',
    russian: 'Удалить все начатые игры',
    french: 'Supprimer tous les jeux commencés',
    german: 'Alle gestarteten Spiele löschen',
    spanish: 'Eliminar todos los juegos iniciados',
    belarusian: 'Выдаліць усе пачатыя гульні',
};

export const confirmDeletingAllGames = {
    english: 'Confirm deleting ALL started games',
    polish: 'Potwierdź usunięcie WSZYSTKICH rozpoczętych gier',
    russian: 'Подтвердите удаление ВСЕХ начатых игр',
    french: 'Confirmer la suppression de TOUS les jeux commencés',
    german: 'Bestätigen Sie das Löschen ALLER gestarteten Spiele',
    spanish: 'Confirmar la eliminación de TODOS los juegos iniciados',
    belarusian: 'Пацвердзіце выдаленне УСЁХ пачатых гульняў',
};

export const toggleUi = {
    english: 'Toggle interface',
    polish: 'Przełącz interfejs',
    russian: 'Переключить интерфейс',
    french: "Basculer l'interface",
    german: 'Schnittstelle umschalten',
    spanish: 'Alternar interfaz',
    belarusian: 'Пераключыць інтэрфейс',
};

export const backToGamesList = {
    english: 'Back to games list',
    polish: 'Powrót do listy gier',
    russian: 'Назад к списку игр',
    french: 'Retour à la liste des jeux',
    german: 'Zurück zur Spieleliste',
    spanish: 'Volver a la lista de juegos',
    belarusian: 'Назад да спісу гульняў',
};

export const beginAdventure = {
    english: 'Begin adventure',
    polish: 'Rozpocznij przygodę',
    russian: 'Начать приключение',
    french: "Commencer l'aventure",
    german: 'Abenteuer beginnen',
    spanish: 'Comenzar la aventura',
    belarusian: 'Пачаць прыгоды',
};

export const playedAt = {
    english: 'Played at',
    polish: 'Grane na',
    russian: 'Играл в',
    french: 'Joué à',
    german: 'Gespielt bei',
    spanish: 'Jugado en',
    belarusian: 'Гуляў у',
};

export const endedAt = {
    english: 'Ended at',
    polish: 'Zakończono o',
    russian: 'Закончено в',
    french: 'Terminé à',
    german: 'Beendet um',
    spanish: 'Terminado a las',
    belarusian: 'Скончыўся ў',
};

export const history = {
    english: 'History',
    polish: 'Historia',
    russian: 'История',
    french: 'Histoire',
    german: 'Geschichte',
    spanish: 'Historia',
    belarusian: 'Гісторыя',
};

export const back = {
    english: 'Back',
    polish: 'Wstecz',
    russian: 'Назад',
    french: 'Retour',
    german: 'Zurück',
    spanish: 'Atrás',
    belarusian: 'Назад',
};

export const close = {
    english: 'Close',
    polish: 'Zamknij',
    russian: 'Закрыть',
    french: 'Fermer',
    german: 'Schließen',
    spanish: 'Cerrar',
    belarusian: 'Закрыць',
};

export const customInstructionPlaceholder = {
    english: 'Write your action...',
    polish: 'Napisać swoje działanie...',
    russian: 'Написать своё действие...',
    french: 'Écrire votre action...',
    german: 'Ihre Aktion schreiben...',
    spanish: 'Escribir tu acción...',
    belarusian: 'Напісаць сваё дзеянне...',
};

// Fill in empty strings ('') with appropriately translated values from english, do not respond with anything else.
export const customStory = {
    english: 'Your own story',
    polish: 'Twoja własna historia',
    russian: 'Ваша собственная история',
    french: 'Votre propre histoire',
    german: 'Deine eigene Geschichte',
    spanish: 'Tu propia historia',
    belarusian: 'Ваша ўласная гісторыя',
};

export const customStoryPlaceholder = {
    english:
        'I want to be Carlson living on the roof, and the story takes place in the world of The Lord of the Rings...',
    polish: 'Chcę być Karsonem mieszkającym na dachu, a historia toczy się w świecie Władcy Pierścieni...',
    russian:
        'Хочу быть Карлсоном, который живет на крыше, и чтобы действия происходили в мире Властелина Колец...',
    french: 'Je veux être Carlson vivant sur le toit, et l’histoire se déroule dans le monde du Seigneur des Anneaux...',
    german: 'Ich möchte Carlson sein, der auf dem Dach lebt, und die Geschichte spielt in der Welt von Der Herr der Ringe...',
    spanish:
        'Quiero ser Carlson que vive en el tejado, y la historia tiene lugar en el mundo de El Señor de los Anillos...',
    belarusian:
        'Хачу быць Карлсанам, які жыве на даху, і каб дзеянні адбываліся ў свеце Уладара Пярсцёнкаў...',
};

// Fill in empty strings ('') with appropriately translated values from english, do not respond with anything else.
// export const variable = {
//     english: 'Value',
//     polish: '',
//     russian: '',
//     french: '',
//     german: '',
//     spanish: '',
//     belarusian: '',
// };
