import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiService } from './services/api-service';
import { cache } from './services/cache-service';
import ClipLoader from 'react-spinners/ClipLoader';
import LanguageSelect from './components/languageSelect';
import DisclaimerModal from './components/disclaimer';
import PreRegistrationBanner from './components/preRegistrationBanner';
import * as localization from './services/localization';
import StoriesFooter from './components/storiesFooter';

const apiService = new ApiService();

function App() {
    const [availableStories, setAvailableStories] = useState([]);
    const [startedGames, setStartedGames] = useState([]);
    const [userId, setUserId] = useState('');
    const navigate = useNavigate();
    const initialized = useRef(false);

    const [loading, setLoading] = useState(false);
    const [language, setLanguage] = useState('english');
    const [imagesDim, setImagesDim] = useState('16-9');
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [promptVersion, setPromptVersion] = useState(null);
    const { userId: userIdFromSession, versionId, storyId } = useParams();
    const [customStory, setCustomStory] = useState('');

    useEffect(() => {
        if (initialized.current) {
            return;
        }

        if (userIdFromSession) {
            cache.set('userId', userIdFromSession);
            navigate('/');
        }

        initialized.current = true;

        if (versionId) {
            localStorage.setItem('prompt-version', versionId);
        }

        setPromptVersion(localStorage.getItem('prompt-version'));

        login();
    }, []);

    async function login({ newLanguage, newScreenDimensions } = {}) {
        if (loading) return;

        setLoading(true);
        let userId = cache.get('userId');

        if (!userId) {
            setShowDisclaimer(true);
        }

        const loginResponse = await apiService.login({
            userId,
            language: newLanguage,
            screenDimensions: newScreenDimensions,
        });
        cache.set('userId', loginResponse.userId);
        cache.set(
            'locationImageContainerUrl',
            loginResponse.locationImageContainerUrl
        );
        setUserId(loginResponse.userId);
        setAvailableStories(loginResponse.availableStories ?? []);
        setStartedGames(loginResponse.startedGames ?? []);
        setLanguage(loginResponse.user.language);
        setImagesDim(loginResponse.user.screenDimensions);
        cache.set('language', loginResponse.user.language);

        setLoading(false);

        if (storyId) {
            const story = (loginResponse.availableStories ?? []).find(
                (x) => x.id === storyId
            );
            if (story) {
                await startQuest(story, { tempUserId: loginResponse.userId });
            } else {
                navigate('/demo');
            }
        }
    }

    async function startQuest(story, options) {
        if (loading) return;

        setLoading(true);
        const game = await apiService.startGame({
            userId: options?.tempUserId ?? userId,
            storyId: story.id,
        });
        setLoading(false);
        navigate(`/game/${game.gameId}`, { state: { game } });
    }

    async function startCustomQuest() {
        if (loading) return;

        setLoading(true);
        const game = await apiService.startCustomGame({
            userId: userId,
            prompt: customStory,
        });
        setLoading(false);
        navigate(`/game/${game.gameId}`, { state: { game } });
    }

    async function continueQuest(startedGame) {
        if (loading) return;

        if (startedGame.isEnded) return;

        setLoading(true);
        const game = await apiService.getGame({
            userId,
            gameId: startedGame.id,
        });
        setLoading(false);
        navigate(`/game/${startedGame.id}`, { state: { game } });
    }

    const [deleteUnlocked, setDeleteUnlocked] = useState(false);
    async function deleteSavedGames() {
        if (loading) return;

        if (!deleteUnlocked) {
            setDeleteUnlocked(true);
            return;
        }

        setLoading(true);
        await apiService.deleteGames({ userId });
        setLoading(false);
        await login();
    }

    const [sessionCopied, setSessionCopied] = useState(false);
    function copyMe() {
        navigator.clipboard.writeText(
            `${window.location.href}session/${userId}`
        );
        setSessionCopied(true);
    }

    async function changeLanguage(newLanguage) {
        return login({ newLanguage });
    }

    async function toggleImages() {
        let newScreenDimensions;
        if (imagesDim === '1-1') {
            newScreenDimensions = '16-9';
        } else if (imagesDim === '16-9') {
            newScreenDimensions = 'none';
        } else if (imagesDim === 'none') {
            newScreenDimensions = '1-1';
        }

        if (newScreenDimensions) {
            await login({ newScreenDimensions });
        }
    }

    return (
        <div>
            {' '}
            <PreRegistrationBanner />
            <div className="grid place-items-center">
                {showDisclaimer && <DisclaimerModal></DisclaimerModal>}
                <ClipLoader
                    color={'#36d7b7'}
                    loading={loading}
                    size={100}
                    cssOverride={{
                        zIndex: 100,
                        margin: 'auto',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        position: 'fixed',
                    }}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />

                <LanguageSelect
                    selected={language}
                    onChange={changeLanguage}
                ></LanguageSelect>
                <h3 className="mt-4 mb-2 text-3xl font-bold">
                    {localization.availableGames[language]}
                </h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                    {availableStories.map((story, index) => (
                        <div
                            key={story.id}
                            onClick={() => startQuest(story)}
                            className="hover:cursor-pointer hover:shadow-xl m-3 max-w-sm rounded overflow-hidden shadow-lg"
                        >
                            <img className="w-full" src={story.imageUrl} />
                            <div className="px-6 py-4">
                                <div className="font-bold text-xl mb-2">
                                    {story.name}
                                </div>
                                <p className="text-gray-700 text-base">
                                    {story.description}
                                </p>
                            </div>
                        </div>
                    ))}
                    {availableStories?.length > 0 && (
                        <div className=" m-3 max-w-sm rounded overflow-hidden shadow-lg">
                            <img
                                className="w-full"
                                src={availableStories[0].imageUrl.replace(
                                    'medieval',
                                    'custom'
                                )}
                            />
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    startCustomQuest();
                                }}
                                className="px-6 py-4"
                            >
                                {/* <div className="font-bold text-xl mb-2"> */}
                                {/* {localization.customStory[language]} */}
                                {/* </div> */}
                                <div className="text-gray-700 text-base flex flex-col">
                                    <textarea
                                        className="w-full h-[100px] resize-none"
                                        type="text"
                                        required
                                        minLength={10}
                                        maxLength={5000}
                                        value={customStory}
                                        onChange={(e) => {
                                            setCustomStory(e.target.value);
                                        }}
                                        placeholder={
                                            localization.customStoryPlaceholder[
                                                language
                                            ]
                                        }
                                    ></textarea>
                                    <button
                                        type="submit"
                                        className="mt-3 bg-secondary hover:bg-outline text-onSecondary font-bold py-2 px-4 rounded-full"
                                    >
                                        {localization.customStory[language]}
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
                {startedGames.length > 0 && (
                    <div className="grid place-items-center">
                        <h3 className="mt-4 mb-2 text-3xl font-bold">
                            {localization.startedGames[language]}
                        </h3>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                            {startedGames.map((startedGame, index) => (
                                <div
                                    key={startedGame.id}
                                    onClick={() => continueQuest(startedGame)}
                                    className={` m-3 max-w-sm rounded overflow-hidden shadow-lg ${
                                        startedGame.isEnded
                                            ? 'bg-neutral-300'
                                            : 'hover:cursor-pointer hover:shadow-xl'
                                    }`}
                                >
                                    <img
                                        className="w-full"
                                        src={startedGame.imageUrl}
                                    />
                                    <div className="px-6 py-4">
                                        <div className="font-bold text-xl mb-2 flex">
                                            <p>{startedGame.name}</p>
                                            <div className="flex-1"></div>
                                            <div class="group relative flex justify-center">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="size-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                                    />
                                                </svg>
                                                <div class=" absolute w-[300px] top-10 right-0 scale-0 rounded bg-background p-2 text-xs text-onBackground group-hover:scale-100">
                                                    <p className=" text-base">
                                                        {
                                                            localization
                                                                .startedAt[
                                                                language
                                                            ]
                                                        }
                                                        :{' '}
                                                        {new Date(
                                                            startedGame.startedAt
                                                        ).toLocaleString()}
                                                    </p>
                                                    {startedGame.playedAt && (
                                                        <p className=" text-base">
                                                            {
                                                                localization
                                                                    .playedAt[
                                                                    language
                                                                ]
                                                            }
                                                            :{' '}
                                                            {new Date(
                                                                startedGame.playedAt
                                                            ).toLocaleString()}
                                                        </p>
                                                    )}
                                                    {startedGame.endedAt && (
                                                        <p className=" text-base">
                                                            {
                                                                localization
                                                                    .endedAt[
                                                                    language
                                                                ]
                                                            }
                                                            :{' '}
                                                            {new Date(
                                                                startedGame.endedAt
                                                            ).toLocaleString()}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-gray-700 text-base">
                                            {startedGame.description}
                                        </p>
                                        {/* <p className="text-gray-300 text-sm text-base">
                                        Save ID: {startedGame.id}
                                    </p> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="mt-10 grid grid-cols-2">
                    <button
                        onClick={() => copyMe()}
                        className="mb-3 mr-5 bg-secondary hover:bg-outline text-onSecondary font-bold py-2 px-4 rounded-full"
                    >
                        {sessionCopied
                            ? localization.sessionCopied[language]
                            : localization.copyMySession[language]}
                    </button>
                    {/*<button
                    onClick={() => toggleImages()}
                    className="mb-3 mr-5 bg-secondary hover:bg-outline text-onSecondary font-bold py-2 px-4 rounded-full"
                >
                    Images:{' '}
                    {imagesDim.charAt(0).toUpperCase() +
                        imagesDim.slice(1).replace('-', ':')}
                </button>*/}
                    <button
                        onClick={() => deleteSavedGames()}
                        className="mb-3 bg-secondary hover:bg-outline text-onSecondary font-bold py-2 px-4 rounded-full"
                    >
                        {deleteUnlocked
                            ? localization.confirmDeletingAllGames[language]
                            : localization.deleteAllGames[language]}
                    </button>
                </div>

                {promptVersion && (
                    <p className="text-gray-500">
                        Prompt version: {promptVersion}
                    </p>
                )}
            </div>
            {/* <StoriesFooter></StoriesFooter> */}
        </div>
    );
}

export default App;
